import { LOGGED_IN, TOKEN_EXPIRED, LOGGED_OUT, REGISTERED, USER_LOADING, AUTH_ERROR, USER_UPDATE } from "../actions";

const initialState = {
    user: null,
    isAuthenticated: false,
    isLoading: false,
    token: null,
    isTokenExpired: false,
}

export default function AuthReducer(state=initialState, action){
    switch(action.type){
        case USER_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case LOGGED_IN:
        case REGISTERED:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state, 
                user:action.payload.user, 
                token: action.payload.token,
                isAuthenticated:true,
                isLoading:false,
            };
            
        case TOKEN_EXPIRED:
            state.isTokenExpired = true;
        case LOGGED_OUT:
            localStorage.removeItem('token');
            return {
                ...state,
                user: null,
                isAuthenticated:false,
                token:null,
                isLoading:false,
            };
        
        case AUTH_ERROR:
            return {
                ...state,
                isLoading:false,
                authErrorStatus: action.payload,
            };
        case USER_UPDATE:
            return {
                ...state,
                user:{
                    ...state.user,
                    ...action.payload.user,
                }
            }
        default:
            return state;
    }
}