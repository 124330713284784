// the order of the tiles on the homepage would be based on this.

export const regionalDescription = [
    {
        category: "REGIONAL_MPO",
        verbose: "Metropolitan Planning Organization",
        description: "Twenty-one area-specific transportation statistical reports are provided. The areas include Metropolitan Planning Organization (MPO) regions, a consolidated rural area, a consolidated urban area, and a combined statewide region."
    },
    {
        category: "REGIONAL_REDC",
        verbose: "Regional Economic Development Council",
        description: "Ten area-specific transportation statistical reports are provided for Regional Economic Development Council Regions.",
    },
    {
        category: "REGIONAL_DOTR",
        verbose: "DOT Region",
        description: "Twelve area-specific transportation statistical reports are provided. The areas include eleven NYSDOT regions and a combined statewide region.",
    },
    {
        category: "REGIONAL_CDTC",
        verbose: "Capital District Transportation Committee",
        description: "County-level transportation statistics are provided for Capital District Transportation Committee (CDTC).",
    },
    {
        category: "REGIONAL_EMFAC",
        verbose: "Emission Factor Profile",
        description: "Emission factor profiles are provided for twenty-five areas in the state to be used in regional air quality modeling. The transportation statistics include regional percent of vehicle trips, vehicle miles traveled, and average trip length by time of day.",
    },

]

export const specialDescription = [
    {
        category: "SPECIAL_ELDERLY",
        verbose: "Elderly",
        description: "Analysis of travel patterns and characteristics of elderly population in New York State."
    },
    {
        category:"SPECIAL_LOWINCOME",
        verbose: "Low-income",
        description: "Analysis of travel patterns and characteristics of low-income population in New York State."
    },
    {
        category:"SPECIAL_RURAL",
        verbose: "Rural Areas",
        description: "Analysis of travel patterns and characteristics of population in rural areas of New York State."
    },
    {
        category:"SPECIAL_MILLENIALS",
        verbose: "Millennials",
        description: "Analysis of travel patterns and characteristics of millennials in New York State."
    },
    // {
    //     category:"SPECIAL_TBD",
    //     //verbose: "To be determined",
    //     description: "Includes reports such as alternative fuel and electric vehicles."
    // },
]

export const otherDescription = [
    {
        category: "OTHER_ALTFUEL",
        verbose: "Alternative Fuel Vehicle",
        description: "Analysis of alternative fuel vehicle usage and owner demographics in New York State."
    },
    {
        category: "OTHER_ODDATA",
        verbose: "Origin-Destination Data",
        description: "Reports and data sets produced using NextGen NHTS Origin-Destination data and other related data."
    },
    {
        category: "OTHER_FREIGHTDATA",
        verbose: "Freight Data",
        description: "Reports and data sets produced using freight-related data sets."
    },
    {
        category: "OTHER_PRESANDPUB",
        verbose: "Presentations and Publications",
        description: "Research outputs presented in professional conferences as well as published articles in technical journals."
    },
]


// verbose (will be used in the homepage)
const regional_statistics_verbose = {};
const special_population_verbose = {};
const other_reports_verbose = {};
regionalDescription.forEach((description) => {
  other_reports_verbose[description.category] = description.verbose;
});
specialDescription.forEach((description) => {
    other_reports_verbose[description.category] = description.verbose;
});
otherDescription.forEach((description) => {
    other_reports_verbose[description.category] = description.verbose;
});


// Category
export const report_category_choices = Object.keys(regional_statistics_verbose).concat(
    Object.keys(special_population_verbose), Object.keys(other_reports_verbose)
  );
  
export const report_category_verbose = Object.assign({}, regional_statistics_verbose,
    special_population_verbose, other_reports_verbose);




