import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from './pages/HomePage';
import ContactUs from './pages/ContactUs';
import { Provider } from 'react-redux';
import store from '../store';
import { loadUser } from '../actions/auth';
import ReportFullView from './report/ReportFullView';
import AddReportForm from './report/AddReportForm';
import RequireAuth from './utils/RequireAuth';
import { ReportSearchView } from './report/PaginatedSearchableReportView';


function App() {
  const [, setIsLoadingUser] = useState(false)
  
  useMemo(()=>{
    // For some reason this part is called twice so a lock variable is used
    setIsLoadingUser((isLoading)=>{
      if(!isLoading)  loadUser()
      return true
    })
  }, [])


  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/upload_report" element={<RequireAuth requireSuper={true} Component={AddReportForm} />}/>
          <Route path='/report/:id' element={<RequireAuth Component={ReportFullView}/>}/>
          <Route path='/search' element={<RequireAuth Component={ReportSearchView}/>}/>
          <Route path='/contact' element={<RequireAuth Component={ContactUs}/>}/>
          <Route path='/' Component={HomePage}/>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
