import axios from 'axios';
import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { formConfig } from '../../actions/auth';
import Header from '../Header';
import { report_category_choices } from './constants';



export default function AddReportForm() {
    // UI States
    const [reportAsReport, setReportAsReport] = useState(true);
    const [highlightAsHighlight, setHighlightAsHighlight] = useState(true);
    const [extractReportTitle, setExtractReportTitle] = useState(true);
    const [isDragOver, setIsDragOver] = useState(false);

    // Report Fields
    const [title, setTitle] = useState("");
    const [short_description, setDescription] = useState("");
    const [reportFile, setReportFile] = useState(null);
    const [highlightsFile, setHighlightsFile] = useState(null);
    const [publishDate, setPublishDate] = useState("");
    const [otherFiles, setOtherFiles] = useState([]);
    const [reportCategory, setReportCategory] = useState("OTHER");

    // idle | error | uploading | complete
    const [uploadState, setUploadState] = useState("idle");

    // required for allowing the navigation
    const [uploadedReportId, setUploadedReportId] = useState(null);
    
    // It is the error dictionary returned by the server
    const [uploadError, setUploadError] = useState({}); 

    const uploadReport = async () => {
        setUploadState("uploading");
        setUploadError({});
        const form = new FormData();
        
        form.append("title", title);
        form.append("short_description", short_description);
        form.append("report_file", reportFile);
        form.append("publish_date", publishDate);
        form.append("category", reportCategory);
        form.append("hash", "123"); // Just Some value

        if (highlightsFile)
            form.append("highlights_file", highlightsFile);
        form.append("publish_date", publishDate);

        let i = 0;
        for(let file of otherFiles){
            form.append(`files${i}`, file)
            i++;
        }
        
        try{
            const response = await axios.post("/api/v1/report/", form, formConfig())
            setUploadState("complete");
            setUploadedReportId(response.data.id);
        }catch(e){
            console.log(e);
            setUploadState("error")
            if(e.response.data)
                setUploadError(e.response.data)
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault()
        setIsDragOver(true)
    }

    const clearAll = () => {
        setTitle("");
        setDescription("");
        setReportFile(null);
        setHighlightsFile(null);
        setPublishDate("");
        setOtherFiles([]);
        setReportCategory(1);
        setUploadState("idle");
        setUploadedReportId(null);
        setUploadError({});
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    }

    const handleDrop = (e) => {
        setIsDragOver(false);
        if(e.dataTransfer && e.dataTransfer.files.length !== 0){
            e.preventDefault()
            handleAllFileChange({target:{files: e.dataTransfer.files}})
        }
    }

    const handleAllFileChange = (e) => {
        let reportFile, highlightsFile;
        let otherFiles = [];

        for(let file of e.target.files){
            if(reportAsReport && file.name.endsWith("report.pdf"))
                reportFile = file;
            else if(highlightAsHighlight && file.name.endsWith("highlights.pdf"))
                highlightsFile = file;
            else{
                otherFiles.push(file)
            }
        }

        if(reportFile){
            handleReportChange({target:{files:[reportFile]}})
        }

        if(highlightsFile){
            handleHighlightChange({target:{files:[highlightsFile]}})
        }

        setOtherFiles(otherFiles)
    }

    const handleReportChange = (e) => {
        if(e.target.files?.length===0) return;
        const file = e.target.files[0];

        // Extract the title of the report and set the file
        const title = file.name.replace(".pdf", "").trim()
        setReportFile(file);
        if(extractReportTitle) setTitle(title)
    }

    const handleHighlightChange = (e) => {
        if(e.target.files?.length===0) return;
        setHighlightsFile(e.target.files[0]);
    }

    if(uploadState==="complete"){
        return (
            <div className='fixed bg-white w-full h-screen flex item-center flex-col mt-40 items-center'>
                <span className='text-green-700'>
                    <i className='fa fa-check mr-2'/> 
                    The report has been sucessfully uploaded
                </span>
                <button 
                    onClick={clearAll}
                    className='border p-2 m-2 my-4'
                >
                    Add another
                </button>

                <Link className='border p-2' to={`/report/${uploadedReportId}`}>
                    View Uploaded
                </Link>
            </div>
        )
    }

    if(uploadState === "uploading"){
        return (
            <div className='w-full h-screen flex items-center flex-col justify-center'>
                <i className='fa fa-spinner text-4xl fa-spin'/>
                <span className='mt-2'>Uploading</span>
            </div>
        )
    }

    return (
        <div className='p-2 py-4 font-light mt-14 mb-14 landscape:border rounded-lg m-auto w-1/2 portrait:w-full shadow-lg'>
            <Header showUpload={false} />
            <div className='text-xl p-2'>Upload Report Form</div>
            <div
                onDragOver={handleDragOver} 
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
                className={
                    'm-2 flex items-center justify-center transition-all text-black h-40 mt-4 border-black border-4 border-dashed rounded-xl'
                    + (isDragOver?" bg-slate-100 scale-95":"")
                }
            >
                <i className='fa fa-file-o text-3xl mr-2'/>
                <span>
                    Drag N Drop Files
                </span>
                <span className='ml-2 text-3xl'>/</span>
                <div>
                    <input 
                        onChange={handleAllFileChange}
                        id="file-input" 
                        type="file" 
                        multiple={true}
                        className='m-2 hidden border p-2 bg-slate-50 rounded'
                    />
                    <label htmlFor='file-input' className='block cursor-pointer p-2 m-2 border-2 rounded border-black'>
                        Select
                    </label>
                </div>
            </div>

            <div className='flex m-2'>
                {
                    !reportAsReport &&
                    <div>
                        <input 
                            onChange={handleReportChange}
                            multiple={false}
                            id="report-input" 
                            type="file" 
                            className='m-2 hidden border p-2 bg-slate-50 rounded'
                        />
                        <label htmlFor='report-input' className='text-sm cursor-pointer p-1 m-2 border rounded'>
                            Select Report
                        </label>
                    </div>
                }
                
                
                <div className=''>
                    <input
                        unchecked={ reportAsReport }
                        onChange={ ()=>setReportAsReport(!reportAsReport) }
                        type="checkbox" 
                    /> 
                    <label className='ml-1 text-sm'>
                        Automatically use file ending with <strong>report.pdf</strong> as report file
                    </label>
                </div>
            </div>
            
            <div className='flex m-2'>
                {
                    !highlightAsHighlight &&
                    <div>
                        <input 
                            onChange={handleHighlightChange}
                            multiple={false}
                            id="highlights-input"
                            type="file" 
                            className='m-2 hidden border p-2 bg-slate-50 rounded'
                        />
                        <label htmlFor='highlights-input' className='text-xs cursor-pointer p-1 m-2 ml-1 border rounded'>
                            Select Highlights
                        </label>
                    </div>
                }

                <div className=''>
                    <input
                        unchecked = {highlightAsHighlight}
                        onChange = {()=>setHighlightAsHighlight(!highlightAsHighlight)}
                        type="checkbox" 
                    /> 
                    <label className='ml-1 text-sm'>
                        Automatically use file ending with <strong>highlights.pdf</strong> as highlights file
                    </label>
                </div>
            </div>

            <div className='m-2 mt-8 flex flex-col'>
                <div className='flex items-center flex-wrap pb-1'>
                    <label htmlFor="report-title" className='mr-4   flex items-center'>
                        <span className='block'>Report Title</span>
                        <span className='text-sm ml-1'>(required*)</span>
                    </label>

                    <div>
                        <input 
                            id="title-from-report-file"
                            type="checkbox"
                            onChange={()=>setExtractReportTitle(!extractReportTitle)}
                            unchecked={extractReportTitle}
                        />
                        <label htmlFor="title-from-report-file" className='ml-1 text-sm'>
                            Extract title from report file name
                        </label>
                    </div>
                </div>
                <input 
                    value={title}
                    maxLength={255}
                    onChange={(e)=>setTitle(e.target.value)}
                    id="report-title"
                    className='border p-2 rounded focus:outline-primary'
                />
            </div>
            
            <div className='m-2 mt-4 flex flex-col'>
                <label htmlFor="report-short-description" className=''>
                    Short Description
                    <span className='text-sm ml-1'>
                        (optional)
                    </span>
                </label>
                <textarea 
                    value={short_description}
                    onChange={(e)=>setDescription(e.target.value)}
                    style={{minHeight:100}}
                    id="report-short-description"
                    className='border p-2 rounded focus:outline-primary'
                />
            </div>
            
            {/* Cateogry select */}
            <div className='m-2 mt-4'>
                <label htmlFor="category-type-select" className=''>
                    Category:
                </label>
                <select
                    value={reportCategory}
                    onChange={(e)=>setReportCategory(e.target.value)}
                    id="category-type-select"
                    className='ml-2 border p-2 rounded focus:outline-primary'
                >
                    {
                        report_category_choices.map(choice=>
                            <option value={choice} key={choice+"AddReportForm"}>
                                {choice}
                            </option>
                        )
                    }
                </select>
            </div>

            {/* Publish Date */}
            <div className='m-2 mt-4'>
                <label htmlFor="publish-date" className=''>
                    Publish Date:
                </label>
                
                <input
                    type="date"
                    id="publish-date"
                    className='ml-2 border p-2 rounded focus:outline-primary'
                    value={publishDate}
                    onChange={(e)=>setPublishDate(e.target.value)}
                />
            </div>
            
            {/* Upload Summary Part  */}
            <div className='p-2 pt-6 mt-4 border-t'>
                <div className=''>
                    Upload Summary
                </div>
                
                <ul className='list-decimal ml-8'>
                    <li className='my-1'>
                        Title: 
                        {
                            !title?
                            <span className='text-error ml-1'>(required*)</span>:
                            <span className=' text-blue-600 ml-1'>{title}</span>
                        }

                        {
                            uploadError.title &&
                            <span className='text-error ml-1'>{uploadError.title}</span>
                        }
                    </li>

                    <li className='my-1'>
                        Description: 
                        <span className='ml-1'>{short_description.length} characters</span>
                        {
                            uploadError.short_description &&
                            <span className='text-warningml-1'>{uploadError.short_description}</span>
                        }
                    </li>

                    <li className='my-1'>
                        Report file: 
                        {
                            !reportFile ? 
                            <span className='text-error ml-1'>(required*)</span>:
                            <span className='text-blue-600 ml-1'>({reportFile.name})</span>
                        }

                        {
                            uploadError.report_file &&
                            <span className='text-error ml-1'>{uploadError.report_file}</span>
                        }
                    </li>
                    <li className='my-1'>
                        Highlights file:
                        {/* its okay if highlights file is not provided */}
                        {/* {
                            !highlightsFile ?
                            <span className='text-error ml-1'>(not provided)</span>:
                            <span className='text-blue-600 ml-1'>({highlightsFile.name})</span>
                        } */}

                        {
                            uploadError.highlights_file &&
                            <span className='text-error ml-1'>{uploadError.highlights_file}</span>
                        }
                    </li>

                    <li className='my-1'>
                        Other files:
                        <span className='ml-1'>
                            {otherFiles.length} provided {otherFiles.map(f=>f.name).join(", ")}
                        </span>

                        {
                            uploadError.files &&
                            <ul className='text-red-600 list-disc'>
                                {
                                    Object.keys(uploadError.files).map(key=>
                                        <li key={key+"files"}>{key}: uploadError.files[key]</li>
                                    )
                                }
                            </ul>
                        }
                    </li>
                </ul>
            </div>


            <div className='p-2 pt-4 mt-4 border-t'>
                {/* Show upload button only when there are both reportFile and tiltle */}
                {
                    reportFile &&
                    title &&
                    publishDate &&
                    <button 
                        onClick={uploadReport}
                        className='border p-2 rounded hover:scale-105 transition-transform'
                    >
                        <i className='fa fa-upload mr-2'/> Upload
                    </button>
                }
            </div>
        </div>
    )
}
