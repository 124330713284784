import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import { HeaderFooterContainer } from '../Header';
import LoadingOverlay from './LoadingOverlay';

export default function RequireAuth({Component, requireSuper=false}){
    const auth = useSelector(state=>state.auth);

    if(auth.isLoading) return <LoadingOverlay/>

    if(auth.isAuthenticated){
        if(requireSuper && !auth.user?.is_superuser)
            return(
                <HeaderFooterContainer>
                    <div className='p-2 font-light'>
                        Sorry this page is only accesible to super user.
                    </div>
                </HeaderFooterContainer>
            )


        return React.createElement(Component)
    }

    return <Navigate to="/" replace/>
}
