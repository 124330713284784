import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { tokenConfig } from '../../actions/auth';
import { report_category_verbose } from './constants';

let reportTree = null;
let isLoading = false;
const loadCallbacks = [];

const loadReportTree = (onSuccess, onFail) => {
  if(reportTree!=null){
    onSuccess(reportTree);
    return;
  }

  loadCallbacks.push({onSuccess, onFail});
  if(isLoading) return;

  isLoading = true;
  axios.get("/api/v1/report/as_tree/", tokenConfig())
    .then((res)=>{
      reportTree = res.data;
      for(let c of loadCallbacks) c.onSuccess?.(reportTree)
    }).catch((e)=>{
      console.log(e);
      for(let c of loadCallbacks) c.onFail?.(reportTree)
    });
}

export const useReportTree = ()=>{
  const [tree, setTree] = useState({status:"loading", data:null});

  useEffect(()=>{
    loadReportTree((data)=>setTree({status:'loaded', data}), ()=>setTree({status:"error"}))
  }, []);

  return tree;
}

function Tree({data, pKey}){
  if(Array.isArray(data)){
    return (
      <div className='grid grid-cols-3 ml-4'>
        {data.map(d=>
          <Link 
            className='hover:underline' 
            key={pKey+d.id} 
            to={`/report/${d.id}`}
          >
            {d.title}
          </Link>
        )}
      </div>
    );
  }

  return Object.keys(data).map((key, index)=>
    <div key={key+pKey} className='ml-4'>
      {
        report_category_verbose[key] ?
        <Link className='font-semibold hover:underline' to={`reports/?category=${key}&no_search=true`}>
          {index+1}. {report_category_verbose[key] || key}
        </Link>:
        <span className='font-semibold'>{key}</span>
      }
      <Tree key={pKey} data={data[key]}/>
    </div>
  )
}

export function SiteMap() {
  const tree = useReportTree();

  return (
    <div className='p-2 flex flex-col'>
      <span className='text-xl'>Site Map</span>
      {
        tree.status === "loaded" &&
        <Tree data={tree.data} pKey="__sitemap__"/>
      }
    </div>
  )
}