import { Link } from 'react-router-dom';
import { useQueryAdderRemover, addQueryParams } from '../../hooks/Route'

import axios from 'axios';
import React, { useEffect, useState } from 'react'



// export default function PageDetails({paginatedResponse, PAGE_SIZE, load}) {
export default function PageDetails({paginatedResponse, load}) {
    const [add, , query] = useQueryAdderRemover();
    const page = Number.isNaN(parseInt(query.get("page")))?1:parseInt(query.get("page"));

    const handleLoad = (page)=>(e)=>{
        e.preventDefault();
        const q = add({page})
        load(q);
    }



    const [PAGE_SIZE, setPageSize] = useState(null);

    useEffect(() => {
        axios.get('/api/page_size/')
            .then(response => {
                setPageSize(response.data.page_size);
            })
            .catch(error => {
                console.error('Error fetching page size:', error);
            });
    }, []);



    return (
        // <div className='text-sm flex items-center'>
            
            
        //     <div className='m-2 text-gray-800'>
        //         Showing: &nbsp;
        //         {(page-1) * PAGE_SIZE}-
        //         {Math.min(page*PAGE_SIZE, paginatedResponse.count)} of {paginatedResponse.count}             
        //     </div>
            
            
            
        //     <div>
        //         {
        //             paginatedResponse.previous  &&
        //             <Link 
        //                 className='hover:underline text-gray-800 mr-2' 
        //                 onClick={handleLoad(page-1)} 
        //                 to={"/search/"+addQueryParams(query, {page:page-1})}
        //             >
        //                 Prev
        //             </Link>
        //         } 
                
        //         {
        //             paginatedResponse.next  &&
        //             <>
        //             {"|"}
        //             <Link 
        //                 className='hover:underline text-gray-800 ml-2' 
        //                 onClick={handleLoad(page+1)} 
        //                 to={"/search/?"+addQueryParams(query, {page:page+1})}
        //             >
        //                 Next
        //             </Link>
        //             </>
        //         } 
        //     </div>
        // </div>
    "")
}
