import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer 
      style={{minHeight:200}} 
      className="font-light portrait:mt-12 flex flex-col bg-gray-50 justify-between border-t"
    >
      <div className='grid grid-cols-3 p-6 portrait:grid-cols-1 potrait:p-2 flex-wrap'>
        <div className='flex flex-col portrait:mb-4'>
          <span className='text-lg mb-2 font-normal'>Useful Links</span>
          <a 
            className='hover:underline mb-1'
            href="https://www.ornl.gov/" 
            target="_blank"
            rel = "noreferrer"
          >
            ORNL (Oak Ridge National Laboratory)
          </a>
          <a 
            className='hover:underline mb-1'
            href="https://nhts.ornl.gov/" 
            target="_blank"
            rel="noreferrer"
          >
            NHTS (National Household Travel Survey)
          </a>
          <a 
            className='hover:underline mb-1'
            href="https://www.dot.ny.gov/index" 
            target="_blank"
            rel="noreferrer"
          >
            NYSDOT
          </a>
          <a
            className='hover:underline mb-1'
            href="https://www.ornl.gov/ornlhome/disclaimers.shtml"
            target="_blank"
            rel="noreferrer"
          >
            Security Notice
          </a>
        </div>

        <div className='flex flex-col portrait:mb-4'>
          {/* <span className='text-lg mb-2 font-normal'>Pages</span>
          <Link to="/about" className='hover:underline mb-1'>
            About Us
          </Link>

          <Link to="/faq" className='hover:underline mb-1'>
            FAQ
          </Link> */}

          <Link to="/contact" className='hover:underline mb-1'>
            Contact Us
          </Link>
        </div>

        <div className='inline'>
          <span className='font-normal mr-1'>Developed by</span>
          <a
            className='hover:underline ml-0' 
            href="https://www.ornl.gov/ntrc/" 
            target="_blank"
            rel="noreferrer"
          >National Transportation Research Center
          </a>
          <span className='mx-1'>at</span>
          <a 
            className='hover:underline ml-0'
            href="https://www.ornl.gov/" 
            target="_blank"
            rel="noreferrer"
          >Oak Ridge National Laboratory
          </a>
        </div>
      </div>

      {/* <div className="text-center p-3 bg-gray-100 border-t text-gray-500">
        <Link to="/">
          <i className='fa fa-home mr-2 text-xl' aria-hidden="true"/>
          Home
        </Link>
       </div> */}
    </footer>
  )
}
