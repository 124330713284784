import axios from "axios";
import React, { useEffect, useState } from "react";

export default function TextViewer({url}) {
    /* the component state is an HTML string */
    const [text, setText] = useState("");

    // loading | loaded | error
    const [loadState, setLoadState] = useState("loading");
    const load = async () => {
        try{
            setLoadState("loading")
            const res = await axios.get(url);
            console.log(res.data)
            setText(res.data);
            setLoadState("loaded");
        }catch(e){
            console.log(e);
            setLoadState("error");
        }
    }
    
    /* Fetch and update the state once */
    useEffect(() => { load() }, [url]);

    if(loadState==="loading")
        return (
            <div className='flex items-center justify-center w-full'>
                <i className='fa fa-spinner fa-spin text-3xl'/>
            </div>
        )

    if(loadState==="error")
        return (
            <div className='flex items-center text-red-500 justify-center w-full'>
                <i class="fa fa-frown-o" aria-hidden="true"/> Error while loading
            </div>
        )


    return (
        <pre style={{height:window.innerHeight-48}} className="w-full p-2">
            {text}
        </pre>
    );
}