import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { tokenConfig } from '../../actions/auth';
import Header, { HeaderContainer } from '../Header';
import { useSelector } from 'react-redux';
import FileItem, { typeFromUrl } from '../docviewer/FileItem';
import ExcelViewer from '../docviewer/ExcelViewer';
import TextViewer from '../docviewer/TextViewer';
import { useQueryAdderRemover } from '../../hooks/Route';

// import JSZip from 'jszip';



export default function ReportFullView() {
    const {id} = useParams();

    // loading | loaded | error
    const [loadState, setLoadState] =  useState("loading");
    const [ report, setReport ] = useState(null);
    const auth = useSelector(state=>state.auth);
    const [add, , query] = useQueryAdderRemover();

    const fileIndexFromQuery = ()=>{
        const index = parseInt(query.get("fileIndex"));
        if(Number.isNaN(index)) return 0;
        return index;
    }

    // Formats the doc viewer's url to 
    const allFiles = ()=>{
        const uri = [report.report_file]; 
        // if highlights file is present, push it 
        if(report.highlights_file)
            uri.push(report.highlights_file);
        
        for(let file of report.files){
            uri.push(file.file)
        }

        return uri;
    }



    // const generateZipFile = async () => {
    //     const zip = new JSZip();

    //     // allFiles_list = allFiles().map(url => decodeURIComponent(url.split('/').pop())).filter(filename => /[_\s]/.test(filename));
    //     // console.log(allFiles_list)
    //     // const fileName = `zipped_${allFiles_list.split('.')[0]}`; // Declare the fileName variable
    //     const fileName = "zipped";
    //     console.log(allFiles())
    //     // Add each file to the zip
    //     allFiles().forEach((fileUrl, index) => {
    //         zip.file(fileName, fileUrl);
    //     });
        
        
    //     // Generate the zip file
    //     const zipFile = await zip.generateAsync({ type: 'blob' });
        
    //     // Create a download link for the zip file
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = URL.createObjectURL(zipFile);
    //     downloadLink.download = `${fileName}.zip`;
        
    //     // Trigger the download
    //     downloadLink.click();
    //   };




    const ListItems = ({ f = allFiles() }) => {
        const fileNames = f.map(url => decodeURIComponent(url.split('/').pop())).filter(filename => /[_\s]/.test(filename));
        return (
          <ul className="text-sm text-gray-500 list-disc">
            {fileNames.find(item => item.includes("highlights")) && (
              <li>Highlights present certain travel behaviors and patterns</li>
            )}
            {fileNames.find(item => item.includes("AppC")) && (
              <li>Adjustments for tables impacted by methodological change in estimation of trip distance are presented in Appendix C</li>
            )}
            {fileNames.find(item => item.includes("AppD")) && (
              <li>Margin of errors for several tables with estimates that might potentially be impacted by outliers are provided in Appendix D</li>
            )}
            {fileNames.find(item => item.includes("Table_1")) && (
              <li>Table 1 presents summary statistics on demographic characteristics and total travel</li>
            )}
            {fileNames.find(item => item.includes("Sample_size")) && (
              <li>Sample size spreadsheet corresponds to the main table spreadsheet</li>
            )}
            {fileNames.find(item => item.includes("Main_tables")) && (
              <li>Main tables are numbered according to the numbering scheme of the 1990 NPTS Databook</li>
                )} 
            {/* if AppC and AppD are not present, the main tables show something more */}
            {fileNames.find(item => item.includes("Main_tables")) && (!fileNames.find(item => item.includes("AppC"))) && (
              <li>Margin of error statistics are provided in a separate worksheet next to the main table</li>
            )}
          </ul>
        );
      };



    const load = async () => {
        try{
            setLoadState("loading");
            const res = await axios.get(`/api/v1/report/${id}/`, tokenConfig())
            setReport(res.data);
            setLoadState("loaded");
        }catch(e){
            console.log(e);
            setLoadState("error");
        }
    }

    useEffect(()=>{
        if(auth.user)   load()
    },[id, auth]);

    if(loadState==="loading")
        return (
            <HeaderContainer>
                <div className='flex items-center justify-center w-full'>
                    <i className='fa fa-spinner fa-spin text-3xl'/>
                </div>
            </HeaderContainer>
        )

    
    if(loadState==="error")
        return (
            <HeaderContainer>
                <div className='flex p-2 flex-col items-center text-warning justify-center w-full'>
                    <span>
                        <i class="fa fa-frown-o mr-2" aria-hidden="true"/> Error while loading
                    </span>
                    <button onClick={ ()=>load() } className='m-2 p-2 border rounded'>
                        <i className='fa fa-repeat mr-2'/>
                        Retry
                    </button>
                </div>
            </HeaderContainer>
        )
    

    // Some calculations    
    const fileIndex = fileIndexFromQuery()
    const currentURL = allFiles()[fileIndex];
    const type = typeFromUrl(currentURL);


    return (
        <div className='flex w-full h-screen overflow-hidden'>
            <Header />
            {/* <TextViewer url={currentURL}/> */}
            <div className='w-9/12 pb-96 h-screen border-r pt-12'>
                {
                    type === "txt" &&
                    <TextViewer url={currentURL}/>
                }
                {
                   (type === "pdf") &&
                   <embed 
                        src={currentURL} 
                        width={"100%"} 
                        height={window.innerHeight-48}
                    />
                }

                {
                    (type === "jpg" || type === "png" || type === "jpeg") &&
                    <embed 
                        src={currentURL} 
                        width={"40%"} 
                        // height={window.innerHeight-48}
                    />
                }
                
                { 
                    (type==="xlsx" || type==="xls") && 
                    <ExcelViewer url={currentURL} />
                }
            </div>
            <div className='w-3/12 h-screen overflow-y-auto py-2 pt-12'>
                <div className='flex flex-col'>
                    <h1 className='text-2xl px-2 pt-2 font-light'>{report.title}</h1>
                    <div className='text-sm flex flex-col text-gray-500 w-full px-2 pb-1 border-b'>
                        {/* <span>Category: {report.category}</span> */}
                        {/* <span>Uploaded by: {report.uploaded_by?.username}</span>  */}
                        {/* <span>Added at: {report.added_at?.substring(0,10)}</span>  */}
                        <span>Published date: {report.publish_date?.substring(0,10)}</span>
                        <span>Description: {report.short_description?.substring(0,400)  || "No description"}</span>
                        <span>Files count: {report.files.length + (report.highlights_file?2:1)}</span>
                    </div>
                </div>

                <div className="text-xl px-2 py-2">Files</div>
                <div className = "border-b pb-1 ">
                    <ul>
                        {allFiles().map((url, index)=>
                            <FileItem 
                                key={url} 
                                url={url}
                                selected={fileIndex === index}
                                onClick={()=>add({fileIndex:index})}
                            />
                        )}
                    </ul>
                    {/* <ul>
                        <FileItem onClick={generateZipFile}>Download All as Zip</FileItem>
                    </ul> */}
                </div>
                
                <div className="text-xl px-2 py-2">Notes</div>
                    <ul className="text-sm pl-7 text-gray-500 list-disc">
                        <ListItems />
                    </ul>
                </div>
        </div>
        )
}
