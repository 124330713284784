import React from 'react'

export default function LoadingOverlay({children}) {
    return (
        <div 
            className="backdrop-blur bg-white bg-opacity-80 overflow-x-hidden flex items-center justify-center" 
            style={{position:"fixed", top:0, left:0, right:0, bottom:0, zIndex:1000}}>
            {children || <i className="fa fa-spinner fa-spin text-6xl text-slate-500"/>}
        </div>
    )
}