import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Login from '../auth/Login'
import { HeaderFooterContainer } from '../Header'
import LoadingOverlay from '../utils/LoadingOverlay'
import newYorkMap from '../../assets/newyorkstate.png'
import ReportTiles from '../report/ReportTiles'

export default function HomePage() {
    const auth = useSelector(state => state.auth)

    if (auth.isLoading) return <LoadingOverlay />
    
    // Show the login page
    if(!auth.user)
        return (
            <HeaderFooterContainer>
                <div className='w-full pt-10 pb-12 flex items-center justify-center'>
                    <Login />
                </div>
            </HeaderFooterContainer>
        )

    return (
        <HeaderFooterContainer>
            <div className='flex w-full portrait:flex-col'>
                <div className='flex items-center ml-16 w-2/3 portrait:mx-4 portrait:py-1 portrait:w-11/12'>
                    <div className='flex-0 flex-col'>
                        {/* <span className='text-3xl'>Welcome to NYSDOT</span> */}
                        <br/>
                        {/* <span className = "text-xl"> */}
                        <span>
                            This website presents research reports and data sets developed by Oak Ridge
                            National Laboratory (ORNL) for the New York State Department of Transportation (NYSDOT).
                            The navigation pane at the top will allow you to access these –
                            grouped into Regional Statistics, Special Population, and Other Reports.
                        </span>
                        <br/>
                        <br/>
                        
                        {/*Search*/}
                        {/* <Link
                            to={"/search/"}
                            className='border items-center portrait:w-full flex cursor-pointer p-1 px-5 mt-2 h-12 rounded text-gray-500'
                        >
                            <i className='fa fa-search'/>
                            <div className='pl-2 portrait:w-0 portrait:hidden text-xl'>Search the database</div>
                        </Link> */}
                    </div>
                </div>
                <div className='flex-1 flex justify-center pt-8 ml-6 portrait:pt-4'>
                        <img src={newYorkMap}/>
                </div>
            </div>
            <ReportTiles/>
        </HeaderFooterContainer>
    )
        
}