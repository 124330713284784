import React, { useState } from 'react'
import { logOut } from '../../actions/auth'
import Alert from '../utils/Alert'

export default function Logout() {
    const [state, setState] = useState("")

    const handleLogout = ()=>{
        setState("loading")
        logOut(()=>{}, ()=>setState("error"))
    }

    return (
        <>

            {
                state === "show-alert" &&
                <Alert
                   title="Logout"
                   message="Do you want to exit the current session"
                   positive="Yes"
                   negative="No" 
                   onPositive={handleLogout}
                   onNegative={()=>setState("")}
                />

            }

            <button 
                onClick={()=>setState("show-alert")}
                disabled={Boolean(state)}
                className={
                    'border text-primary px-2 py-1 m-1 rounded hover:scale-105 transition-transform ' +
                    (state==="error"?" border-warning text-warning":"")
                }
            >
                {state==="loading" && <i className="fa fa-spinner fa-spin mr-2"/>}
                Logout
            </button>
        </>
    )
}
