import React from "react";
import { useReportTree } from "./ReportTreeView";
import { report_category_verbose } from "./constants";
import { Link } from "react-router-dom";

const dropDownContentClass = 
  'dropdown-content w-80 bg-stone-100 border-1 border-secondary rounded shadow-lg '+
  'absolute hidden text-primary pt-1';
const dropDownItemClass = 'block p-2 w-full rounded hover:text-white hover:bg-secondary border-b'


function ReportSubDrop({data, pKey}){
  if(Array.isArray(data)){
    return (
      <div
        // style={{maxHeight:"70vh", overflowY:"scroll", }} 
        // style={{maxHeight:"60vh"}} 
        className={dropDownContentClass + ' ml-80 -mt-12'}
      >
        {
          data.map((d, index)=>
            <Link to={`/report/${d.id}`} className={dropDownItemClass} key={d.id+pKey}>
              {/* {index+1}. {d.title} */}
               {d.title}
            </Link>
          )
        }
      </div>
    )
  }

  return (
    <div className={dropDownContentClass}>
      {Object.keys(data).map(key=>
        <div className='dropdown cursor-pointer'>
          <Link to={`/search/?category=${key}&no_search=true`} className={dropDownItemClass}>
            {report_category_verbose[key]||key}
          </Link>
          <ReportSubDrop data={data[key]} pKey={pKey}/>
        </div>
      )}
    </div>
  )
}

export function ReportDropDownView(){
  const tree = useReportTree();
  if(tree.status==="loading" || tree.status==="error") return <></>;

  return (
    <>
      {
        Object.keys(tree.data).map(key=>
          <div 
            className='dropdown cursor-pointer inline-block mx-1 p-1 text-primary relative' 
            key={"__dropdown__"+key}
          >
            {key}
            <ReportSubDrop data={tree.data[key]} pKey={"__dropdown__"}/>
          </div>
        )
      }
    </>
  )
}