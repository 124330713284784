import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { login } from '../../actions/auth'

export default function Login() {
    const [state, setState] = useState({username:"", password:""})
    const [error, setError] = useState(null)
    const auth = useSelector(state=>state.auth)


    const handleSubmit = (e) => {
        e.preventDefault()
        setError(null)
        
        login(state, ()=>{
            // Sucessfully logged in
        }, (e)=>{
            setError(e.non_field_errors?.join(", ") || "Network error")
        })
    }

    return (
        <form
            onSubmitCapture={handleSubmit} 
            className='flex flex-col px-2 py-8 border rounded shadow mt-2 w-4/12 portrait:w-11/12'
        >
            <span className='w-full ml-2 text-2xl mb-1'>
                Login
            </span>
            <span className='font-thin ml-2 mb-8'>
                Please log in to access the website's content.
            </span>
            <input 
                value={state.username}
                onChange={(e)=>setState({...state, username: e.target.value})}
                placeholder='Username'
                className='border rounded py-2 px-2 focus:outline-none mx-2 mb-4'
            />

            <input 
                value={state.password}
                onChange={(e)=>setState({...state, password: e.target.value})}
                type="password"
                placeholder='Password' 
                className='border rounded py-2 px-2 focus:outline-none mx-2 mb-6'
            />

            {error && <span className='mx-4 font-bold text-red-500'>{error}</span>}

            <button
                disabled={auth.isLoading}
                formAction='submit' 
                className='border mx-2 bg-primary text-white rounded-lg mt-6 px-2 py-2 hover:scale-105 transition-transform'
            >
                {state==="loading" && <i className="fa fa-spinner fa-spin mr-2"/>}
                Login
            </button>
        </form>
    )
}
