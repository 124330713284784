import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { tokenConfig } from '../../actions/auth';
import { useQueryAdderRemover } from '../../hooks/Route';
import { HeaderFooterContainer } from '../Header';
import PageDetails from '../utils/PageDetails';
import { report_category_choices, report_category_verbose } from './constants';

import ScrollButton from "./ScrollButton.js";

function ReportListView(report) {
    



    return (
        <Link to={`/report/${report.id}`} className='hover:shadow-md text-black border border-gray-300 m-2 rounded-lg flex'>
                <div className='flex flex-auto flex-col px-2 py-2 justify-between'>
                    <div className='flex items-center'>
                        <span className='font-semibold'>{report.title}</span>
                        {/* <span className='ml-2 text-sm text-gray-500'>{report.id}</span> */}
                    </div>

                    <span
                        style={{maxLines:2}} 
                        className='flex py-2 text-xs'>
                        {report.short_description?.substring(0,400) || "No description"}
                    </span>

                    <div className='text-gray-500 text-sm'>
                        {/* {report.category} |  */}
                        {/* By: {report.uploaded_by?.username} |  */}
                        {/* Updated: {report.added_at?.substring(0,10)} */}
                        Published on: {report.publish_date?.substring(0,10)}
                        {/* Files: {report.files.length + (report.highlights_file?2:1)} */}
                    </div>
                </div>        
        </Link>
    )
}


// This constant is set by the server
// const PAGE_SIZE = 10;

const cache = {}; // Cache for the query
export default function PaginatedSearchableReportView() {
    const [add, remove, query] = useQueryAdderRemover();
    const [paginatedResponse, setPaginatedResponse] = useState(null);
    const searchTimeout = useRef(null);

    // idle | loading | error | typing
    const [loadState, setLoadState] = useState("idle");

    const load = async (q) => {
        // Check with in the cache
        if(cache[q]){
            setLoadState("loaded");
            setPaginatedResponse(cache[q]);
            return;
        } 

        if(loadState === "loading") return;
        
        setLoadState("loading");
        try{
            const res = await axios.get("/api/v1/report/?"+q, tokenConfig());
            cache[q] = res.data;
            console.log(res.data);
            setPaginatedResponse(res.data);
            setLoadState("loaded");
        }catch(e){
            console.log(e);
            setLoadState("error");
        }
    }

    const handleSearchChange = (e) => {
        setLoadState("typing");
        if(searchTimeout.current!==null) clearTimeout(searchTimeout.current);
        const q = add({search:e.target.value, page:1});

        // Instead of trying to search after each keystroke we delay the search by 500ms
        // After user stops for 1s after typing the key then we automatically do the search
        searchTimeout.current = setTimeout(()=>load(q), 500);
    }

    const categoryFromQuery = ()=>{
        return query.get("category") || "";
    }

    const orderTypeFromQuery = () => {
        const ordering = query.get("ordering");
        return ordering?.replace("-", "") || "title";
    }
    
    const orderDirectionFromQuery = () => {
        const ordering = query.get("ordering");
        if(!ordering) return "";

        if(ordering.includes("-")) return "-";
        return "";
    }

    const handleCategoryChange = (e)=>{
        if(e.target.value==="") load(remove({category:"", page:1}));
        else load(add({category: e.target.value, page: 1}));
    }

    useEffect(()=>{
        if(query.get("no_search"))  load(query.toString());
    }, [query]);

    return (
        <div className='p-2 pb-6'>
            {/* Stuffs */}
            { !query.get('no_search') &&
                <>
                    <div className='flex flex-wrap'>
                        <input 
                            onChange={handleSearchChange}
                            // 13 is enter key
                            onKeyDown={(e)=>{ e.keyCode===13 && load(query.toString())}}
                            placeholder='Search'
                            value={query.get("search")||""}
                            className='border flex-1 px-4 py-2 rounded-l focus:outline-none'
                        />
                        <button 
                            disabled={Boolean(query.get("search"))}
                            onClick={()=>load(query.toString())}
                            className='bg-primary text-white px-4 rounded-r'
                        >
                            <i className='fa fa-search'/>
                        </button>
                    </div>
                    <div className='pt-2 flex flex-wrap text-xs border-b pb-2 mb-2'>
                        <div className='my-1 mr-2 flex'>
                            <label htmlFor='order_by' className='bg-primary text-white border-primary border-2 rounded-l p-1'>
                                Order By
                            </label>
                            <select 
                                value={orderTypeFromQuery()}
                                onChange={(e)=>load(add({ordering: orderDirectionFromQuery() + e.target.value, page:1}))}
                                id="order_by"
                                className='border focus:outline-none p-1 rounded-r'
                                disabled={loadState==="loading"}
                            >
                                <option value="added_at">Added At</option>
                                <option value="title">Title</option>
                                <option value="publish_date">Published At</option>
                            </select>
                        </div>

                        <div className='flex my-1 mr-2'>
                            <label htmlFor='order_direction' className='bg-primary text-white border-2 border-primary rounded-l p-1'>
                                Direction
                            </label>
                            <select 
                                value={orderDirectionFromQuery()}
                                onChange={(e)=>load(add({ordering: e.target.value + orderTypeFromQuery(), page:1}))}
                                disabled={loadState==="loading"}
                                id="order_direction" 
                                className='border focus:outline-none p-1 rounded-r'
                            >
                                <option value="">Asc</option>
                                <option value="-">Desc</option>
                            </select>
                        </div>

                        <div className='flex my-1 mr-2'>
                            <label htmlFor='category_choice' className='bg-primary text-white border-2 border-primary rounded-l p-1'>
                                Category
                            </label>
                            <select 
                                onChange={handleCategoryChange}
                                value={categoryFromQuery()}
                                disabled={loadState==="loading"}
                                id="category_choice" 
                                className='border w-26 focus:outline-none p-1 rounded-r'
                            >
                                {
                                    report_category_choices.map(choice=>
                                        <option value={choice} key={choice+"PaginatedSearchableReportView"}>
                                            {choice}
                                        </option>
                                    )
                                }
                                <option value={""}>ALL</option> 
                            </select>
                        </div>
                    </div>
                </>
            }

            {
                query.get('category') &&
                query.get('no_search') &&
                <div className='p-2 text-xl font-semibold'>
                    {report_category_verbose[query.get('category')] || query.get('category')} Reports
                </div>
            }


            {
                paginatedResponse &&
                paginatedResponse.count !==0 &&
                <PageDetails
                    paginatedResponse={paginatedResponse}
                    //PAGE_SIZE={PAGE_SIZE}
                    load={load}
                />
            }

            <div className='w-full flex items-center justify-center'>
                {(loadState === "loading" || loadState==="typing") && <i className='fa fa-spinner fa-spin text-3xl mt-8'/>}
                {
                    loadState === "error" && 
                    <span className='text-red-600'>
                        <i className='fa fa-exclamation-circle mr-2'/>
                        Error while loading
                    </span>
                }
            </div>

            <div className='grid grid-cols-3 portrait:grid-cols-1'>
            {
                loadState === "loaded" &&
                paginatedResponse &&
                paginatedResponse.results.map(report=>
                    <ReportListView  key={report.id} {...report}/>
                )
                }
            </div>

            {
                loadState==="loaded" &&
                paginatedResponse &&
                paginatedResponse.count===0 &&
                <div className='flex w-full pt-24 items-center justify-center'>
                    <i 
                        className="fa fa-frown-o mr-2" 
                        aria-hidden="true"
                    />
                    We're currently working on the report and it will be added to our website soon. Please check back later for updates.
                </div>
            }

            {
                loadState === "loaded" &&
                paginatedResponse &&
                paginatedResponse.results.length === 20 &&
                paginatedResponse.count !==0 &&
                <PageDetails
                    paginatedResponse={paginatedResponse}
                    //PAGE_SIZE={PAGE_SIZE}
                    load={load}
                />
            }
        </div>
    )
}

export function ReportSearchView(){
    return (
        <HeaderFooterContainer showSearch={false}>
            <PaginatedSearchableReportView />
            <ScrollButton/>
        </HeaderFooterContainer>
    )
}