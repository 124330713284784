import React, { useEffect, useState } from 'react'

export default function Alert({title, message, children, positive, negative, onPositive, onNegative}) {
    const [opacity, setOpacity] = useState(0.0);

    useEffect(()=>{
        setOpacity(1.0);
    }, []);
    
    return (
        <div
            style={{opacity, transition:"opacity 0.4s", }}
            className="fixed font-light top-0 left-0 justify-center h-screen w-screen bg-black bg-opacity-60 z-40 flex">
            <div 
                className="relative m-auto rounded overflow-hidden bg-white flex flex-col shado">
                <div className="border-b p-2 px-4 font-medium text-xl">{title}</div>
                <div
                    style={{minHeight:"20vh", maxHeight:"90vh", minWidth:"30vw", maxWidth:"80vw"}} 
                    className="p-4 pb-20 overflow-scroll">
                    {children || message}
                </div>

                <div className="absolute bg-white border-t p-2 left-0 right-0 bottom-0 flex flex-row-reverse">
                    <button 
                        onClick={onPositive}
                        className="bg-warning text-white px-3 py-1 rounded shadow">
                        {positive}
                    </button>
                    <button 
                        onClick={onNegative}
                        className="text-gray-600 mr-2 border px-3 py-1 rounded">
                        {negative}
                    </button>
                </div>
            </div>
        </div>
    )
}