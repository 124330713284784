import React from "react";

export function filenameFromUrl(url){
  const splits = url.split("/");
  return splits[splits.length-1];
}

export function typeFromUrl(url=""){
  // Test it on regex101.com will save time
  // Matches the end of the file including the dot
  const regex = /\.[a-zA-Z]+$/

  // If matched return the first element by replacing the dot with empty string
  return url.match(regex)?.[0]?.replace(".", "")
}

export function fileIcon(url){
  const type = typeFromUrl(url)
  switch(type){
    case "pdf": 
      return "fa fa-file-pdf-o";
    case "xlsx": 
    case "xls": 
      return "fa fa-file-excel-o";
    case "txt": 
      return "fa fa-file-text-o";
    
    case "jpg":
    case "png":
    case "jpeg":
      return "fa fa-file-image-o";
    default:
      return "fa fa-file";
  }
}

export default function FileItem({url, onClick, selected=false}){
  return(
    <li 
        onClick={onClick}
        className={
            'list-item px-2 truncate pb-1 font-light cursor-pointer ' + 
            (selected?" text-primary border-b border-primary":"")
        }
    >
      <span className="hover:font-semibold">
        <i className={`${fileIcon(url)} mr-2`} />
        {filenameFromUrl(url)} 
      </span>
      
      <a href={url} target="_blank" rel="noreferrer">
        <i className="fa fa-download ml-2"/>
      </a>
    </li>
  )
}