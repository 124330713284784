import axios from "axios";
import { AUTH_ERROR, LOGGED_IN, LOGGED_OUT, REGISTERED, TOKEN_EXPIRED, USER_LOADED, USER_LOADING, USER_UPDATE } from ".";
import store from "../store";

if(window.location.host==="localhost:3000")
    axios.defaults.baseURL = 'http://127.0.0.1:8000';
else
    axios.defaults.baseURL = window.location.origin;

const printError = (onError)=>(err)=>{
    console.log(err.response||err);
    onError && onError(err.response?err.response.data:{});
}

export function login(data, onSuccess, onError){
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    store.dispatch(dispatch=>dispatch({type: USER_LOADED}));

    const body = JSON.stringify(data);
    axios.post('api/v1/auth/login/', body, config)
        .then((res)=>{
            store.dispatch(dispatch=>{
                dispatch({type:LOGGED_IN, payload:res.data});
            })
            onSuccess && onSuccess(res);
        })
        .catch(printError(onError));
}

export function logOut(onSuccess, onError){
    axios.post("/api/v1/auth/logout/", {}, tokenConfig())
        .then(()=>{
            store.dispatch(dispatch=>dispatch({type:LOGGED_OUT}));
            onSuccess && onSuccess();
        }).catch(printError(onError));
}

export function register(data, onSuccess, onError){
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    const body = JSON.stringify(data);

    axios.post('/api/v1/auth/register/', body, config)
        .then((res)=>{
            store.dispatch(dispatch=>{
                dispatch({type:REGISTERED, payload:res.data})
            })
            onSuccess&&onSuccess(res);
        })
        .catch(printError(onError));
}

export function loadUser(){
    let token = localStorage.getItem('token');
    if(!token){
        store.dispatch(dispatch=>{
            dispatch({type: AUTH_ERROR, payload: null});
        });
        return;
    }

    const config = {
        headers: {
            "Content-type": "application/json",
            'Authorization': `Token ${token}`
        }
    };

    store.dispatch(dispatch=>dispatch({type:USER_LOADING}))
    axios.get('/api/v1/auth/user/', config)
    .then((res)=>{
        store.dispatch(dispatch=>{
            dispatch({ type: LOGGED_IN, payload:{token, ...res.data}});
        })
    })
    .catch((err)=>{
        console.log(err.response||err);
        
        const status = err?.response?.status;
        if(status===401){
            // Token has expired
            store.dispatch(dispatch=>{
                dispatch({type: TOKEN_EXPIRED});
            });
        }else{
            // This error may occur due to no internet connection and so on
            store.dispatch(dispatch=>{
                dispatch({type: AUTH_ERROR, payload: status});
            });
            
        }
    })

}

export function updateUser(formData, onSuccess, onError){
    axios.post('/api/v1/auth/user_update/', formData, formConfig())
    .then(res=>{
        onSuccess&&onSuccess(res.data);
        store.dispatch(dispatch=>{
            dispatch({type:USER_UPDATE, payload: res.data});
        });
    })
    .catch(printError(onError))
}

export const formConfig = ()=>{
    let token = store.getState().auth.token;

    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }

    if (token)
        config.headers['Authorization'] = `Token ${token}`;
    
    return config;
}

export const tokenConfig = () => {
    // Get token from the store
    let token = store.getState().auth.token;

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    if (token)
        config.headers['Authorization'] = `Token ${token}`;

    return config;
}