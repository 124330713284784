import { useNavigate, useLocation } from "react-router-dom";

export const addQueryParams = (query, params)=>{
    // Make sure the parameters are sorted
    for(let k of Object.keys(params).sort()){
         if(query.has(k))
            query.delete(k);
        query.append(k, params[k]);
    }
    return query.toString();
}

export const removeQueryParams = (query, params)=>{
    for(let k in params){
        if(query.has(k))
           query.delete(k);
        else
           query.append(k, params[k]);
   }
   return query.toString();
}

export function useQueryAdder() {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    return [
        function(params, push=false){
            const q = addQueryParams(query, params);
            if(push)
                navigate(location.pathname+'?'+q);
            else
                navigate(location.pathname+'?'+q, {replace: true});
        },
        query
    ]
}

export function useQueryRemover(push=false) {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    return [
        function(params){
            const q = removeQueryParams(query, params);
            if(push)
                navigate(location.pathname+'?'+q);
            else
                navigate(location.pathname+'?'+q, {replace:true});
        },
        query
    ]
}

export function useQueryAdderRemover() {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    return [
        function(params, push){
            const q = addQueryParams(query, params);
            if(push)
                navigate(location.pathname+'?'+q);
            else
                navigate(location.pathname+'?'+q, {replace: true});
            return q;
        },
        function(params){
            const q = removeQueryParams(query, params);
            if(!params)
                navigate(-1);
            else
                navigate(location.pathname+'?'+q, {replace: true});
                
            return q;
        },
        query,
    ]
}