import React from 'react'
import { Link } from 'react-router-dom'
import {useSelector} from 'react-redux'
import Logout from './auth/Logout'
import Footer from './Footer'
import { ReportDropDownView } from './report/ReportDropDownView'

export default function Header({showUpload=true, showSearch=true}) {
    const user = useSelector(state=>state.auth.user)

    return (
        <nav className='fixed bg-gray-100 bg-opacity-50 backdrop-blur-lg z-20 border-b top-0 p-1 h-12 left-0 right-0 flex justify-between items-center'>
            <div className='flex'>
                <Link to="/" className='pl-2 flex items-center text-xl ml-5'>
                &nbsp; <b>Home</b>
                </Link>
                <div className='portrait:w-0 portrait:collapse ml-5'>
                    {user && <ReportDropDownView />}
                </div>
            </div>

            <div className='flex items-center'>
                {/* Admin label */}
                {user?.is_superuser && <span className='text-red-500 text-lg py-1 px-2 rounded-full  mr-2 font-bold'>Admin View</span>}
                

                {/* Search Button
                {user && showSearch&&
                <Link
                    to={"/search/"} 
                    className='border items-center flex w-48 cursor-pointer p-1 px-4 m-1 portrait:px-2 portrait:w-fit h-9 rounded text-gray-500'
                >
                  <i className='fa fa-search'/>  <div className='pl-2 portrait:w-0 portrait:hidden'>Search</div>
                </Link>} */}

                {/* Upload button */}
                {
                    // user?.is_superuser && 
                    user?.is_staff && 
                    showUpload &&
                    <Link to={"/upload_report"} className="m-1 flex items-center p-1 px-2 portrait:p-2 border rounded">
                        <i className='fa fa-upload mr-1 portrait:mr-0'/>
                        <div className='portrait:hidden'>Upload</div>
                    </Link>
                }

                {/* Logout Button */}
                { user && <Logout/>}
            </div>
        </nav>
    )
}

export function HeaderContainer({children, showSearch=true}){
    return (
        <div className='pt-12'>
            <Header showSearch={showSearch}/>
            {children}
        </div>
    )
}


export function HeaderFooterContainer({children, showSearch=true}){
    return(
        <div className='pt-12 flex flex-col' style={{minHeight:"100vh"}}>
            <Header showSearch={showSearch}/>
            <div className='flex-1'>
                {children}
            </div>
            <Footer/>    
        </div>
    )
}