import React from 'react'
import { useSelector } from 'react-redux'
import Login from '../auth/Login'
import { HeaderFooterContainer } from '../Header'
import LoadingOverlay from '../utils/LoadingOverlay'

export default function HomePage() {
    const auth = useSelector(state => state.auth)

    if (auth.isLoading) return <LoadingOverlay />
    
    // Show the login page
    if(!auth.user)
        return (
            <HeaderFooterContainer>
                <div className='w-full pt-10 pb-12 flex items-center justify-center'>
                    <Login />
                </div>
            </HeaderFooterContainer>
        )

    return (
        <HeaderFooterContainer>
            <div className='flex w-full portrait:flex-col'>
                <div className='flex items-center ml-16 w-2/3 portrait:mx-4 portrait:py-1 portrait:w-11/12'>
                    <div className='flex-0 flex-col'>
                        <br />
                        <br />
                        
                        <span>
                            For questions regarding contents or technical issues, please contact Majbah Uddin,&nbsp;
                            <a 
                                className='hover:underline mb-1'
                                href="mailto:uddinm@ornl.gov" 
                                target="_blank"
                                rel="noreferrer"
                            >
                                uddinm@ornl.gov
                            </a>
                        </span>                
                    </div>
                </div>
            </div>
        </HeaderFooterContainer>
    )
        
}