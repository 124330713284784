import React, { useEffect, useState } from "react";
import { read, utils } from 'xlsx';


const MAX_FONT = 32
const MIN_FONT = 9
/**
 * Provides excel viewing functionality using sheet-js library
 * It is slightly modified from sheet-js's official example
 * */
export default function ExcelViewer({url}) {
    /* the component state is an HTML string */
    const [__html, setHtml] = useState("");
    const [wb, setWB] = useState(null);

    // loading | loaded | error
    const [loadState, setLoadState] = useState("loading");
    const [currentSheet, setCurrentSheet] = useState(0);
    const [fontSize, setFontSize] = useState(14);

    const handleFontChange = (newFont) => {
        newFont = Math.min(Math.max(newFont, MIN_FONT), MAX_FONT);
        localStorage.setItem("excel-view-font", newFont);
        setFontSize(newFont);
    }

    const setSheet = (wb, sheet)=>{
        setCurrentSheet(sheet);
        const ws = wb.Sheets[wb.SheetNames[sheet]]; // get the first worksheet
        const data = utils.sheet_to_html(ws); // generate HTML
        setHtml(data); // update state
    }

    const load = async () => {
        try{
            setLoadState("loading")
            const f = await (await fetch(url)).arrayBuffer();
            const wb = read(f); // parse the array buffer
            setWB(wb);
            setSheet(wb, 0);
            setLoadState("loaded");
        }catch(e){
            console.log(e);
            setLoadState("error");
        }
    }
    
    /* Fetch and update the state once */
    useEffect(() => { 
        const fS = parseInt(localStorage.getItem("excel-view-font"))
        if(!Number.isNaN(fS)) setFontSize(fS);
        load() 
    }, [url]);

    if(loadState==="loading")
        return (
            <div className='flex items-center justify-center w-full'>
                <i className='fa fa-spinner fa-spin text-3xl'/>
            </div>
        )

    if(loadState==="error")
        return (
            <div className='flex items-center text-red-500 justify-center w-full'>
                <i class="fa fa-frown-o" aria-hidden="true"/> Error while loading
            </div>
        )


    return (
        <div style={{height:window.innerHeight-48}} className="relative w-full">
            <div 
                className="p-2 w-full overflow-y-scroll pt-12 pb-20"
                style={{height:window.innerHeight-48, fontSize}}
                dangerouslySetInnerHTML={{ __html }}  // IT IS OKAY TO DO SO
            />

            <div 
                className="absolute border-b box-border flex bg-white overflow-x-auto whitespace-nowrap overflow-y-hidden top-0 left-0 right-0">
                {wb.SheetNames.map((n, index) =>
                    <span 
                        onClick={()=>setSheet(wb, index)}
                        key={url+n} 
                        className={
                            "flex-1 px-2 p-1 box-border hover:text-primary " +
                            "cursor-pointer text-sm py-1 border-primary " +
                            (index===currentSheet?"font-bold border-b-2 text-primary":"")
                        }
                    >
                        {n}
                    </span>
                )}
            </div>

            <div className="absolute bottom-0 right-0 p-2">
                <button 
                    disabled={fontSize>=MAX_FONT} 
                    onClick={()=>handleFontChange(fontSize+1)}
                    className="disabled:opacity-40"
                >
                    <i className="fa fa-search-plus p-1 hover:scale-105 transition-transform"/>
                </button>
                <button
                    className="disabled:opacity-40" 
                    disabled={fontSize<=MIN_FONT} 
                    onClick={()=>handleFontChange(fontSize-1)}
                >
                    <i className="fa fa-search-minus p-1 hover:scale-105 transition-transform"/>
                </button>
            </div>
        </div>
    );
}